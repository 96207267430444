<template>
  <div class="home">
    <img
      class="home-img"
      src="https://oss.syounggroup.com/static/file/soyoung-zg/aliyun/soyoung-zg/business/dongjie.png"
      alt=""
    />
    <p class="home-p">该账号已冻结</p>
    <p class="home-p">如有疑问可联系您的专属顾问</p>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
.home {
  text-align: center;
  margin-top: 50px;
  &-img {
    width: 108px;
    height: 110px;
  }
  &-p {
    margin: 10px 0;
  }
}
</style>